<template>
  <div class="application_warp">
    <div class="application_content">
      <p class="coment_title">应用中心</p>
      <div class="application_list" v-for="(allApp, key, index) in allAppList" :key="index">
        <div class="list_title">{{key}}</div>
        <div class="list_li">
           <a-row class="list_row" :gutter="10">
             <a-col class="col_warp" span="3" v-for="app in allApp" :key="app.id" @click="appJump(app)">
              <div class="app_li">
                <div class="li_avatar">
                  <img :src="staticDomainURL+app.appimage" alt="">
                </div>
                <div class="li_name">{{app.appname}}</div>
              </div>
             </a-col>
           </a-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { getAction } from '@/api/manage'
  import { USER_INFO, ACCESS_TOKEN } from "@/store/mutation-types"
  const TumpLink = {H3: '/mesnac/DD/getH3URLDD',
    IFLOW: '/mesnac/DD/getIflowURL', SFA: '/mesnac/DD/getSFAURL',
    SIIP:'/mesnac/DD/getSIIPUrl', YS:'/mesnac/DD/getYSUrl',
    CW:'/mesnac/DD/getCWDSJ',GZJH:'/mesnac/DD/getMHGZJH',CDGZJH:'/mesnac/DD/getMHCDGZJH',
    ZZZX:'/mesnac/DD/getZZZX',XMJXGL:'/mesnac/DD/getXMJXGL',
    SJZZ:'/mesnac/DD/getSJZZGL',LDLSLL: '/mesnac/weaver/login' }

  export default {
    data() {
      return{

        token: Vue.ls.get(ACCESS_TOKEN),
        allAppList: {},
        TumpLink,
        userInfo: Vue.ls.get(USER_INFO),
        username:Vue.ls.get(USER_INFO).username,
        staticDomainURL: window._CONFIG['staticDomainURL'],
      }
    },
    created() {
      this.getAllApp()
    },
    methods: {
      getAllApp() {
        getAction('/mesnac/zmba/zmbApplication/getAll', { column:'apporder', order:'asc'}).then(res => {
          if(res.success) {
            this.allAppList = res.result
            for(let key in this.allAppList) {
              this.allAppList[key].sort(this.compare('apporder'))
            }
            console.log('this.allAppList: ', this.allAppList);
          }
        })
      },
      // 排序
      // 排序方法
      compare(property){
        return function(a,b){
          var value1 = a[property];
          var value2 = b[property];
          return value1 - value2;
        }
      },

      // App 跳转
      appJump(app) {
        console.log('app: ', app);
        if(app.id =='1352506388391149569') {
          this.$router.push({path:'/meeting'})
        } else if(app.appname =='物料信息查询' && app.apppath ==='' || app.apppath ==null) {
          this.$router.push({path:'/MesnacMaterialList'})
        } else {
          if(app.appdescribe == 'tj') {
            let url = TumpLink[app.apppath]
            console.log(url)
            if(url == '/mesnac/DD/getCWDSJ') {
              getAction(url, {employeeNo: this.userInfo.workNo}).then(res => {
                if(res.success) {
                  let name = res.result
                  let location = `http://172.16.77.194:8080/bi/SSO.jsp?code=${name}`
                  var tempwindow=window.open('_blank');
                  tempwindow.location=location;
                } else {
                  this.$message.warn('您没有权限登录!')
                }
              })
            }else if(url == '/mesnac/weaver/login') {
              getAction(url, {username: this.userInfo.workNo}).then(res => {
                var regExp = new RegExp('[\u4E00-\u9FA5\uFE30-\uFFA0]');
                if (regExp.test(res)) {
                  this.$message.error(res)
                } else {
                  var tempwindow=window.open('_blank');
                  tempwindow.location=res
                }
              })
            } else if (app.appname =='优享运营平台'){
              console.log('this.userInfo: ', this.userInfo);
              getAction('mesnac/DD/getUShareURLDD', {username: this.userInfo.username}).then(res => {
                if(res.success) {
                  console.log(res)
                  let tokentrue = res.result
                  let location = `http://ushare.mesnac.com:9300/user/login?token=${tokentrue}`
                  var tempwindow=window.open('_blank');
                  tempwindow.location=location;
                } else {
                  this.$message.warn('您没有权限登录!')
                }
              })
            }
            else if(app.appname =='审计问题整改追踪'){

                getAction(url, {username: this.userInfo.username ,token: this.token}).then(res => {
                    if(res.success) {
                        var tempwindow=window.open('_blank');
                        tempwindow.location=res.result+"&token="+this.token;
                    } else {
                        this.$message.warn(res.message)
                    }
                })
            }
            else{
              getAction(url, {username: this.username ,token: this.token}).then(res => {
                if(res.success) {
                  var tempwindow=window.open('_blank');
                  tempwindow.location=res.result;
                } else {
                  this.$message.warn(res.message)
                }
              })
            }
          } else {
            var tempwindow=window.open('_blank');
            tempwindow.location=app.apppath;
          }

        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .application_warp{
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    // background: url("~@/assets/img/home_bg.png") no-repeat top center / cover;
    background: #f6f6f6;
    .application_content {
      width: 1500px;
      margin: 0 auto;
      background: #fff;
      padding: 20px;
      min-height: 700px;
      .coment_title{
        font-size: 22px;
        color: #101010;
        font-weight: 600;
      }
      .application_list{
        border-bottom: 5px solid #F8DADA;
        margin-bottom: 20px;
        .list_title{
          font-size: 14px;
          color: #101010;
          padding-left: 20px;
          position: relative;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 5px;
            height: 20px;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            left: 0px;
            background-color: #BC0039;
          }
        }
        .list_li {
          margin-left: 30px;
          .list_row{
            width: 100%;
          }
          .app_li{
            // width: 90px;
            text-align: center;
            margin-bottom: 10px;
            cursor: pointer;
            .li_avatar{
              margin: 0 auto;
              height: 70px;
              img{
                width: 70px;
                height: 70px;
              }
            }
            .li_name{
              margin-top: 8px;
              font-size: 14px;
              color: #101010;
              text-overflow:ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      .application_list:last-child {
        border-bottom: none;
      }
    }
  }
</style>
